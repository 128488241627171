import "./style/index.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { ConfiguracoesState, setEmailorCell, setPaginaIdentidade } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { EnviarCodigoModel } from "../../../models/account/enviarCodigoModel";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { AccountState, enviarCodigoFetch, setConfirmouUsuario, verificarPodeUtilizarLoginFetch } from "../../../redux/slices/account/accountSlice";
import { Button, Col, Flex, Form, Image, Input, Row, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import agendConfirImg from "../../../util/assets/agendConf.png";
import Spinner from "../../../components/spinner/spinner";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";
import { login } from "../../../services/accountService";
import { ChatsCircle, Envelope, WhatsappLogo } from "@phosphor-icons/react";

const { Title, Text } = Typography;

interface FormInformarEmail {
  email: string;
}

const InformarEmail = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const accountState: AccountState = useAppSelector((state) => state.account);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Estados Locais
  const [form] = Form.useForm<FormInformarEmail>();

  const submit = (values: FormInformarEmail) => {
    dispatch(setEmailorCell(values.email));

    var args: EnviarCodigoModel = {
      emailOrCell: form.getFieldValue("email"),
      formaEnvio: "email",
      clientId: loginState.empresa,
    };

    dispatch(verificarPodeUtilizarLoginFetch(args));
  };

  const voltarLogin = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          
            <Flex vertical style={{ height: "100%" }} gap={10}>
              <Title style={{ textAlign: "center" }} level={4}>
                INFORME O SEU EMAIL
              </Title>
              <Text style={{ textAlign: "center", color: "#101010", marginTop: "-15" }}>Antes de iniciar o cadastro, precisamos confirmar seu email</Text>
              <Image preview={false} src={agendConfirImg} />
              <Text style={{ textAlign: "center", color: "#101010", marginTop: "-15" }}>Informe o seu email. Enviaremos um código de confirmação para você.</Text>
              <Form layout="vertical" form={form} onFinish={submit}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Digite corretamente seu email",
                          type: "email",
                        },
                      ]}
                    >
                      {/* <MaskedInput className="masked" mask=""  style={{ width: "100%" }}></MaskedInput> */}
                      <Input type="email" style={{ width: "100%" }}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    {
                    loginState.configuracaoAparencia.emailAtivo && 
                    <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                      <Flex align="center" justify="center"  >
                        <Envelope size={20} /> RECEBER CÓDIGO VIA EMAIL
                      </Flex>
                    </Button>
                    }
                  </Col>
                </Row>
              </Form>
              <Button style={{ backgroundColor: "#909090", color: "#ffffff" }} disabled={accountState.loading} onClick={voltarLogin}>
                VOLTAR PARA LOGIN
              </Button>
            </Flex>
        </>
      )}
    </>
  );
};

export default InformarEmail;
