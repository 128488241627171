import "../../acessar/style/index.scss";
import "./style/index.scss";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import ReactInputVerificationCode from "react-input-verification-code";
import { VerificarCodigoModel } from "../../../models/account/verificarCodigoModel";
import { AccountState, verificarCodigoFetch } from "../../../redux/slices/account/accountSlice";
import { ConfiguracoesState, setPaginaIdentidade } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import Spinner from "../../../components/spinner/spinner";
import { Flex, Image, Typography } from "antd";
import { formatEmailHidden, formatTelefoneHidden } from "../../../util/formats";

const { Title, Text, Link } = Typography;

const VerificarCodigo = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const accountState: AccountState = useAppSelector((state) => state.account);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [timerMinutes, setTimerMinutes] = useState<number>(5);
  const [timerSeconds, setTimerSeconds] = useState<number>(0);

  let interval: any;

  const startTimer = () => {
    const countDownDate = new Date().getTime() + 5 * 60 * 1000;

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setTimerMinutes(0);
        setTimerSeconds(0);
      } else {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000); // update every second
  };

  useEffect(() => {
    startTimer();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const submit = async (code: string) => {
    var args: VerificarCodigoModel = {
      codigo: code,
      clientId: loginState.empresa,
      otpSessionId: accountState.OtpSessionId,
    };
    dispatch(verificarCodigoFetch(args));
  };

  const reenviar = () => {
    if (accountState.paciente.email !== null && accountState.paciente.telefones !== null) {
      dispatch(setPaginaIdentidade("enviarCodigo"));
    } else {
      if(loginState.configuracaoAparencia.smsAtivo || loginState.configuracaoAparencia.whatsappAtivo){
        dispatch(setPaginaIdentidade("informarTelefone"));
      }
      else if (loginState.configuracaoAparencia.emailAtivo){
        dispatch(setPaginaIdentidade("informarEmail"));
      }
  }
};

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Title style={{ textAlign: "center" }} level={4}>
            CONFIRMAÇÃO DE IDENTIDADE
          </Title>
          <Flex vertical align="center" style={{ marginTop: "-15px" }}>
            <Text style={{ color: "#101010" }}>Enviamos um código de confirmação para o {configuracoesState.emailOrCell.includes("@") ? "email" : "número"}:</Text>
            <Text style={{ color: "#101010" }}>{configuracoesState.emailOrCell.includes("@") ? formatEmailHidden(configuracoesState.emailOrCell) : formatTelefoneHidden(configuracoesState.emailOrCell)}</Text>
          </Flex>
          <Image preview={false} src={require("../../../util/assets/agendConf.png")} />
          <Flex vertical align="center" justify="center">
            <Text style={{ textAlign: "center" }}>Seu código expira em</Text>
            <Text style={{ fontSize: "25px", textAlign: "center" }}>
              {timerMinutes.toString().padStart(2, "0")}:{timerSeconds.toString().padStart(2, "0")}
            </Text>
          </Flex>
          <Flex className="code" justify="center">
            <ReactInputVerificationCode autoFocus type="text" placeholder="" length={6} onCompleted={(code: string) => submit(code)} />
          </Flex>
          
          {timerMinutes < 4? (
            <Link style={{ textAlign: "center" }} onClick={reenviar}>
              Reenviar código de confirmação
            </Link>
          ) : (
            <Text style={{ textAlign: "center" }}>Para reenviar o código, espere 00:{timerSeconds.toString().padStart(2, "0")}</Text>
          )}
        </Flex>
      )}
    </>
  );
};

export default VerificarCodigo;
