import "./style/index.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { ConfiguracoesState, setEsqueciSenha, setPaginaIdentidade } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { AccountState } from "../../../redux/slices/account/accountSlice";
import Spinner from "../../../components/spinner/spinner";
import { Button, Flex, Image, Typography } from "antd";
import naoEncontradoImg from "../../../util/assets/nao_encontrado.png";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";

const { Title, Text } = Typography;

const CriarCadastro = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const avancar = () => {
    if (loginState.configuracaoAparencia.whatsappAtivo || loginState.configuracaoAparencia.smsAtivo) {
      dispatch(setPaginaIdentidade("informarTelefone"));
    }
    else if (loginState.configuracaoAparencia.emailAtivo) {
      dispatch(setPaginaIdentidade("informarEmail"));
    }
    
  };

  const voltarLogin = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Title style={{ textAlign: "center" }} level={4}>
            CONFIRMAÇÃO DE IDENTIDADE
          </Title>
          <Image preview={false} src={naoEncontradoImg} />
          <Text style={{ textAlign: "center", color: "#101010" }}>Não encontramos o seu cadastro. Para criá-lo, escolha uma das opções abaixo:</Text>
          <Button
            style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff" }}
            onClick={() => {
              avancar();
            }}
          >
            CRIAR CADASTRO
          </Button>
          <Button style={{ backgroundColor: "#909090", color: "#ffffff" }} onClick={voltarLogin}>
            VOLTAR PARA O LOGIN
          </Button>
        </Flex>
      )}
    </>
  );
};

export default CriarCadastro;
