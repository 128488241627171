import "./style/index.scss";
import "moment/locale/pt-br";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { toast } from "react-toastify";
import { CriarUsuarioModel } from "../../../models/account/criarUsuarioModel";
import { UsuarioRegisterModel } from "../../../models/account/usuarioRegisterModel";
import { InformacoesConfirmacaoPacienteModel } from "../../../models/account/informacoesConfirmacaoPacienteModel";
import { PacienteModel } from "../../../models/perfil/pacienteModel";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { AccountState, criarUsuarioFetch } from "../../../redux/slices/account/accountSlice";
import { ConfiguracoesState } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { Button, Checkbox, Col, Flex, Form, Input, InputNumber, Modal, Row, Select, Typography, Image, Tooltip } from "antd";
import Spinner from "../../../components/spinner/spinner";
import { MaskedInput } from "antd-mask-input";
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import imgConfirmacao from "../../../util/assets/confirmacao_id.png";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";
import { formatCPF, formatTelefone } from "../../../util/formats";

moment.locale("pt-br");

const { Title, Text } = Typography;

interface FormValues {
  email: string;
  nome: string;
  password: string;
  cpf: string;
  sexo: string;
  dataNascimento: string;
  peso: number;
  acceptTerm: boolean;
  telefone: string;
}

const CadastroInicial = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const accountState: AccountState = useAppSelector((state) => state.account);

  //Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Estados Locais
  const [form] = Form.useForm<FormValues>();
  const [accept, setAccept] = useState(false);
  const firstInputRef = useRef<any>(null);

  const onChangeTermo = (e: CheckboxChangeEvent) => {
    setAccept(e.target.checked);
  };

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const submit = async (values: FormValues) => {
    if (accept) {
      var usuarioRegister: UsuarioRegisterModel = {
        password: values.password,
        confirmPassword: values.password,
        roleName: "user",
        email: values.email,
        phoneNumber: values.telefone.replace(/[^0-9]/g, ""),//configuracoesState.emailOrCell.replace(/[^0-9]/g, ""),
        clientId: loginState.empresa,
      };

      var informacoesConfirmacaoPaciente: InformacoesConfirmacaoPacienteModel = {
        idUsuario:  accountState.paciente.idUsuario,
        idPaciente: accountState.paciente.idPaciente,
        telefones:  configuracoesState.emailOrCell.includes("@") ? null : [{ numero: configuracoesState.emailOrCell }],
        email:      configuracoesState.emailOrCell.includes("@") ? configuracoesState.emailOrCell : null,
        nomePaciente: accountState.paciente.nomePaciente,
        idUnidade: accountState.paciente.idUnidade,
        pacienteDuplicado: accountState.paciente.pacienteDuplicado,
        busca: accountState.InformacoesBusca,
      };

      var paciente: PacienteModel = {
        idPaciente: null,
        idPacienteResponsavel: null,
        idUsuario: null,
        email: values.email,
        telefones: [{ numero: values.telefone }],
        nomePaciente: values.nome,
        cpf: accountState.InformacoesBusca.cpf,
        sexo: values.sexo,
        dataNascimento: new Date(moment(accountState.InformacoesBusca.dataNascimento, "DD/MM/YYYY").toLocaleString()),
        peso: values.peso.toString(),
        idConvenioPreferencia: 0,
        plano: null,
        dependentes: [],
        parentesco: null,
        idade: null,
      };
      var args: CriarUsuarioModel = {
        usuarioRegister: usuarioRegister,
        idPacienteExistente: null,
        novoPaciente: paciente,
        informacoesConfirmacaoPaciente: informacoesConfirmacaoPaciente,
      };
      dispatch(criarUsuarioFetch(args));
    } else {
      toast.warning("Para poder fazer o cadastro, é necessário aceitar os termos");
    }
  };

  const irParaPainel = () => {
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  const voltarLogin = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10}>
            <Title style={{ textAlign: "center" }} level={4}>
              CONFIRMAÇÃO DE IDENTIDADE
            </Title>
            <Text style={{ color: "#101010", textAlign: "center", marginTop: "-15px" }}>Cadastre seus dados para acesso</Text>
            <Form layout="vertical" 
            onFinish={submit} 
            form={form} 
            initialValues={
              {
                telefone: (loginState.configuracaoAparencia.smsAtivo ||  loginState.configuracaoAparencia.whatsappAtivo)? configuracoesState.emailOrCell : "",
                email: (loginState.configuracaoAparencia.smsAtivo ||  loginState.configuracaoAparencia.whatsappAtivo)? "" : configuracoesState.emailOrCell,
              }
            }
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Nome completo"
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Digite o seu nome completo",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input ref={firstInputRef} type="text" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                { (loginState.configuracaoAparencia.smsAtivo ||  loginState.configuracaoAparencia.whatsappAtivo) ?
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Digite o seu e-mail",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    
                      <Input type="text" /></Form.Item>
                      :
                      <Form.Item
                    label="E-mail"
                    name="email"
                    
                  >
                      <Input type="text" defaultValue={configuracoesState.emailOrCell} disabled />
                    
                  </Form.Item>}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        Senha
                        <Tooltip title="A senha deve conter no mínimo 6 caracteres, incluindo pelo menos uma letra maiúscula e um número.">
                          <InfoCircleOutlined style={{ marginLeft: "3px" }} rev={undefined} />
                        </Tooltip>
                      </>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Digite a sua senha",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Digite a senha" iconRender={(visible) => (visible ? <EyeTwoTone rev={undefined} /> : <EyeInvisibleOutlined rev={undefined} />)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Sexo biológico"
                    name="sexo"
                    rules={[
                      {
                        required: true,
                        message: "Selecione o seu sexo biológico",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      options={[
                        { value: "m", label: "Masculino" },
                        { value: "f", label: "Feminino" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="inputNumberClass">
                  <Form.Item
                    label="Peso"
                    name="peso"
                    rules={[
                      {
                        required: true,
                        message: "Digite o seu peso",
                      },
                    ]}
                  >
                    <InputNumber controls={false} maxLength={3} style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                { (loginState.configuracaoAparencia.smsAtivo ||  loginState.configuracaoAparencia.whatsappAtivo) ?
                  <Form.Item label="Telefone" name="telefone">
                    <MaskedInput className="masked" mask="(00) 00000-0000" inputMode="numeric" style={{ width: "100%" }} disabled/>
                  </Form.Item>
                  :
                  <Form.Item label="Telefone" name="telefone" rules={[
                    {
                      required: true,
                      message: "Digite corretamente seu telefone",
                      pattern: /^\([1-9]{2}\)\s(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
                    },
                  ]}>
                    <MaskedInput className="masked" mask="(00) 00000-0000" inputMode="numeric" style={{ width: "100%" }}/>
                  </Form.Item>

                  }
                    
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="CPF" name="cpf">
                    <Button disabled style={{ width: "100%", textAlign: "left" }}>
                      {formatCPF(accountState.InformacoesBusca.cpf)}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Data de nascimento" name="dataNascimento">
                    <Button disabled style={{ width: "100%", textAlign: "left" }}>
                      {moment(accountState.InformacoesBusca.dataNascimento).format("DD/MM/YYYY")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="" name="acceptTerm">
                    <Checkbox onChange={onChangeTermo}>Aceito os termos de uso do UniClinika Online</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                    SALVAR
                  </Button>
                </Col>
              </Row>
            </Form>
          </Flex>
          <Modal width={320} centered open={accountState.modal} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={imgConfirmacao} />
              <Text style={{ textAlign: "center", color: "#101010" }}>Seu cadastro foi realizado com sucesso</Text>
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={irParaPainel}>
                PROSSEGUIR
              </Button>
              <Button style={{ backgroundColor: "#909090", color: "#ffffff", width: "100%" }} onClick={voltarLogin}>
                VOLTAR PARA LOGIN
              </Button>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};

export default CadastroInicial;
