import { createSlice } from "@reduxjs/toolkit";
import { InformacoesConfirmacaoPacienteBuscaModel, InformacoesConfirmacaoPacienteModel } from "../../../models/account/informacoesConfirmacaoPacienteModel";
import { resetAll } from "../../actions/actions";

export interface AccountState {
  loading: boolean;
  OtpSessionId: string;
  paciente: InformacoesConfirmacaoPacienteModel;
  confirmouUsuario: boolean;
  modal: boolean;
  loadingMensagem: boolean;
  mensagem: string;
  InformacoesBusca: InformacoesConfirmacaoPacienteBuscaModel;
}

const initialState: AccountState = {
  loading: false,
  OtpSessionId: "",
  paciente: {} as InformacoesConfirmacaoPacienteModel,
  confirmouUsuario: false,
  modal: false,
  loadingMensagem: false,
  mensagem: "",
  InformacoesBusca: {} as InformacoesConfirmacaoPacienteBuscaModel,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    criarUsuarioFetch: (state, action) => {},
    alterarSenhaFetch: (state, action) => {},
    recuperarSenhaFetch: (state, action) => {},
    enviarCodigoFetch: (state, action) => {},
    setOtpSessionId: (state, action) => {
      state.OtpSessionId = action.payload;
    },
    verificarCodigoFetch: (state, action) => {},
    confirmacaoUsuarioFetch: (state, action) => {},
    setPaciente: (state, action) => {
      state.paciente = action.payload;
    },
    setConfirmouUsuario: (state, action) => {
      state.confirmouUsuario = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    resetPaciente: (state) => {
      state.paciente = {} as InformacoesConfirmacaoPacienteModel;
    },
    mensagemAtualizacaoCadastroFetch: (state, action) => {},
    setLoadingMensagem: (state, action) => {
      state.loadingMensagem = action.payload;
    },
    setMensagem: (state, action) => {
      state.mensagem = action.payload;
    },
    setInformacoesBusca: (state, action) => {
      state.InformacoesBusca = action.payload;
    },
    verificarPodeUtilizarLoginFetch: (state, action) => {},
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  criarUsuarioFetch,
  alterarSenhaFetch,
  recuperarSenhaFetch,
  enviarCodigoFetch,
  setOtpSessionId,
  verificarCodigoFetch,
  confirmacaoUsuarioFetch,
  setPaciente,
  setConfirmouUsuario,
  setModal,
  resetPaciente,
  mensagemAtualizacaoCadastroFetch,
  setLoadingMensagem,
  setMensagem,
  setInformacoesBusca,
  verificarPodeUtilizarLoginFetch,
} = accountSlice.actions;

export default accountSlice.reducer;
