import "./style/index.scss";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { useNavigate } from "react-router-dom";
import { ConfirmacaoUsuarioModel } from "../../models/account/confirmacaoUsuarioModel";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { AccountState, confirmacaoUsuarioFetch, setInformacoesBusca } from "../../redux/slices/account/accountSlice";
import moment from "moment";
import { Button, Col, Flex, Form, Input, Row, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import { ConfiguracoesState, setEsqueciSenha, setPaginaIdentidade, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import Spinner from "../../components/spinner/spinner";
import { toast } from "react-toastify";

const { Title, Text } = Typography;

interface FormConfirmacao {
  cpf: string;
  dataNascimento: string;
  primeiroNome: string;
}

const ConfirmarUsuario = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const accountState: AccountState = useAppSelector((state) => state.account);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [form] = Form.useForm<FormConfirmacao>();

  useEffect(() => {
    form.setFieldValue("primeiroNome", null);
    dispatch(setVoltar(false));
    if (accountState.confirmouUsuario && !accountState.paciente.pacienteDuplicado) {
      navigate("/identidade/" + loginState.empresa, { replace: true });
      if (accountState.paciente.idUsuario === null) {
        dispatch(setEsqueciSenha(false));
      }
      if (accountState.paciente.idUsuario !== null) {
        dispatch(setEsqueciSenha(true));
      }
      if (accountState.paciente.idPaciente !== null) {
        if (loginState.configuracaoAparencia.smsAtivo || loginState.configuracaoAparencia.whatsappAtivo){
          if ( (accountState.paciente.telefones === null || (accountState.paciente.telefones !== null && accountState.paciente.telefones.length === 0)) && accountState.paciente.email === null) {
            dispatch(setPaginaIdentidade("informarTelefone"));
          }
          else {
            dispatch(setPaginaIdentidade("enviarCodigo"));
          }
        }
        else if(loginState.configuracaoAparencia.emailAtivo){
          if ( accountState.paciente.email === null) {
            dispatch(setPaginaIdentidade("informarEmail"));
          }
          else {
            dispatch(setPaginaIdentidade("enviarCodigo"));
          }
        }
        
      } else {
        dispatch(setPaginaIdentidade("criarCadastro"));
      }
    }
    if(accountState.paciente.pacienteDuplicado){
      toast.warning("O Paciente encontra-se duplicado, digite o nome para poder continuar");
    }
  }, [accountState.paciente]);

  const validateCPF = (cpf: any) => {
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let result = true;

    [9, 10].forEach((j) => {
      let sum = 0;
      let r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach((e: any, i: any) => {
          sum += parseInt(e) * (j + 2 - (i + 1));
        });
      r = 11 - (sum % 11);
      r = r > 9 ? 0 : r; // If the result is 10 or 11, set it to 0
      if (r != cpf.substring(j, j + 1)) result = false;
    });

    return result;
  };

  const validateDateOfBirth = (dob: string) => {
    const dobParts = dob.split("/");

    if (dobParts.length !== 3 || dobParts[0].length !== 2 || dobParts[1].length !== 2 || dobParts[2].length !== 4) {
      return false;
    }

    const dobDate = new Date(`${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`);

    if (dobDate.toString() === "Invalid Date") {
      return false;
    }
    if (dobDate.getTime() > Date.now()) {
      return false;
    }

    return true;
  };

  const submit = async (values: FormConfirmacao) => {
    if (validateCPF(values.cpf.replace(/[^0-9]/g, "")) && validateDateOfBirth(values.dataNascimento)) {
      const args: ConfirmacaoUsuarioModel = {
        cpf: values.cpf.replace(/[^0-9]/g, ""),
        dataNascimento: new Date(moment(values.dataNascimento, "DD/MM/YYYY").toLocaleString()),
        clientId: loginState.empresa,
        primeiroNome: values.primeiroNome,
      };
      dispatch(setInformacoesBusca({ ...args }));
      dispatch(confirmacaoUsuarioFetch(args));
    } else {
      toast.error("CPF ou Data de nascimento inválidos");
    }
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Title style={{ textAlign: "center" }} level={4}>
            CONFIRMAÇÃO DE IDENTIDADE
          </Title>
          <Text style={{ marginTop: "-15px", textAlign: "center", color: "#101010" }}>Digite seus dados para localizarmos seu cadastro</Text>
          <Form layout="vertical" form={form} onFinish={submit}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="CPF"
                  name="cpf"
                  rules={[
                    {
                      required: true,
                      message: "Digite corretamente CPF",
                      pattern: /^\d{3}\x2E\d{3}\x2E\d{3}\x2D\d{2}$/,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <MaskedInput className="masked" mask="000.000.000-00" inputMode="numeric" style={{ width: "100%" }}></MaskedInput>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Data de nascimento"
                  name="dataNascimento"
                  rules={[
                    {
                      required: true,
                      message: "Digite corretamente sua data de nascimento",
                      pattern: /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <MaskedInput className="masked" mask="00/00/0000" inputMode="numeric" style={{ width: "100%" }}></MaskedInput>
                </Form.Item>
              </Col>
            </Row>
            {accountState.paciente.pacienteDuplicado !== null && accountState.paciente.pacienteDuplicado === true ? (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Primeiro nome" name="primeiroNome">
                    <Input maxLength={30} type="text" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row gutter={16}>
              <Col span={24}>
                <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                  AVANÇAR
                </Button>
              </Col>
            </Row>
          </Form>
        </Flex>
      )}
    </>
  );
};

export default ConfirmarUsuario;
