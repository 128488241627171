import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import cadastrarAtualizarUsuario from "./cadastrarAtualizarUsuario";
import CadastroInicial from "./cadastroInicial";
import criarCadastro from "./criarCadastro";
import enviarCodigo from "./enviarCodigo";
import informarTelefone from "./informarTelefone";
import verificarCodigo from "./verificarCodigo";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { Typography } from "antd";
import { setConfirmouUsuario } from "../../redux/slices/account/accountSlice";
import { resetAll, resetPersistedStates } from "../../redux/actions/actions";
import { useEffect } from "react";
import InformarEmail from "./informarEmail";

const { Title, Text } = Typography;

const NotFound = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const voltar = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
    dispatch(setConfirmouUsuario(false));
  };

  useEffect(() => {
    voltar();
  }, []);

  return <></>;
};
const pages: any = {
  cadastrarAtualizarUsuario: cadastrarAtualizarUsuario,
  criarCadastro: criarCadastro,
  enviarCodigo: enviarCodigo,
  informarTelefone: informarTelefone,
  informarEmail: InformarEmail,
  verificarCodigo: verificarCodigo,
  cadastroInicial: CadastroInicial,
  notFound: NotFound,
};

const IdentidadePageController = () => {
  let configuracoesState = useAppSelector((state) => state.configuracoes);

  const Page = pages[configuracoesState.paginaIdentidade];

  return (
    <>
      <Page />
    </>
  );
};

export default IdentidadePageController;
