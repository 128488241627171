import { call, put, takeEvery } from "redux-saga/effects";
import { alterarSenha, configuracaoEmpresa, confirmacaoUsuario, criarUsuario, enviarCodigo, login, mensagemAtualizacaoCadastro, recuperarSenha, verificarCodigo, verificarPodeUtilizarLogin } from "../../../services/accountService";
import { setConfig, setToken } from "../../slices/account/loginSlice";
import { toast } from "react-toastify";
import { ConfiguracoesState, setEsqueciSenha, setLoading, setPaginaIdentidade } from "../../slices/configuracoes/configuracoesSlice";
import { AccountState, resetPaciente, setConfirmouUsuario, setLoadingMensagem, setMensagem, setModal, setOtpSessionId, setPaciente } from "../../slices/account/accountSlice";
import { appSelect } from "../../../util/hooks";
import { perfilLogin } from "../../../services/perfilService";
import { setPerfil } from "../../slices/perfil/perfilSlice";
import { CarrinhoState, setMontagemCarrinho } from "../../slices/carrinho/carrinhoSlice";

//login
function* callLogin(action: any): any {
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;
  try {
    yield put(setLoading(true));
    const data = yield call(login, action.payload);
    const response = data.data.data;
    yield put(setToken(response.accessToken));
    const dataPerfil = yield call(perfilLogin, response.accessToken);
    const responsePerfil = dataPerfil.data.data;
    yield put(setPerfil(responsePerfil.value));
    yield put(setMontagemCarrinho({ ...carrinhoState.montagemCarrinho, idPaciente: responsePerfil.value.idPaciente }));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* loginSaga() {
  yield takeEvery("login/loginFetch", callLogin);
}

//criar usuario
function* callCriarUsuario(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(criarUsuario, action.payload);
    const response = data.data.data.token.accessToken;
    yield put(setToken(response));
    yield put(setModal(true));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* criarUsuarioSaga() {
  yield takeEvery("account/criarUsuarioFetch", callCriarUsuario);
}

//alterar senha
function* callAlterarSenha(action: any): any {
  try {
    yield put(setLoading(true));
    yield call(alterarSenha, action.payload);
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* alterarSenhaSaga() {
  yield takeEvery("account/alterarSenhaFetch", callAlterarSenha);
}

//recuperar senha
function* callRecuperarSenha(action: any): any {
  try {
    yield put(setLoading(true));
    yield call(recuperarSenha, action.payload);
    yield put(setModal(true));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* recuperarSenhaSaga() {
  yield takeEvery("account/recuperarSenhaFetch", callRecuperarSenha);
}

//enviar codigo
function* callEnviarCodigo(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(enviarCodigo, action.payload);
    const response = data.data.data;
    yield put(setOtpSessionId(response.otpSessionId));
    yield put(setPaginaIdentidade("verificarCodigo"));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* enviarCodigoSaga() {
  yield takeEvery("account/enviarCodigoFetch", callEnviarCodigo);
}

//verificar codigo
function* callVerificarCodigo(action: any): any {
  const accountState: AccountState = yield appSelect((state) => state.account) as Generator<any, any, any>;
  try {
    yield put(setLoading(true));
    yield call(verificarCodigo, action.payload);
    if (accountState.paciente.idPaciente !== null) {
      yield put(setPaginaIdentidade("cadastrarAtualizarUsuario"));
    } else {
      yield put(setPaginaIdentidade("cadastroInicial"));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* verificarCodigoSaga() {
  yield takeEvery("account/verificarCodigoFetch", callVerificarCodigo);
}

//confirmarcao usuario
function* callConfirmacaoUsuario(action: any): any {
  const configuracoesState: ConfiguracoesState = yield appSelect((state) => state.configuracoes) as Generator<any, any, any>;
  try {
    yield put(setLoading(true));
    const data = yield call(confirmacaoUsuario, action.payload);
    const response = data.data.data;
    if (response.idPaciente === null) {
      yield put(setEsqueciSenha(false));
    }
    yield put(setPaciente(response));
    if (configuracoesState.esqueciSenha === true) {
      yield put(setPaginaIdentidade("enviarCodigo"));
    } else {
      yield put(setPaginaIdentidade("criarCadastro"));
    }
    yield put(setConfirmouUsuario(true));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* confirmacaoUsuarioSaga() {
  yield takeEvery("account/confirmacaoUsuarioFetch", callConfirmacaoUsuario);
}

//mensagemAtualizacaoCadastro
function* callMensagemAtualizacaoCadastro(action: any): any {
  try {
    yield put(setLoadingMensagem(true));

    const data = yield call(mensagemAtualizacaoCadastro, action.payload);
    const response = data.data.data;
    yield put(setMensagem(response));

    yield put(setLoadingMensagem(false));
  } catch (error: any) {
    yield put(setLoadingMensagem(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* mensagemAtualizacaoCadastroSaga() {
  yield takeEvery("account/mensagemAtualizacaoCadastroFetch", callMensagemAtualizacaoCadastro);
}

//verificarPodeUtilizarLogin
function* callVerificarPodeUtilizarLogin(action: any): any {
  try {
    yield put(setLoading(true));
    yield call(verificarPodeUtilizarLogin, action.payload);
    const data = yield call(enviarCodigo, action.payload);
    const response = data.data.data;
    yield put(setOtpSessionId(response.otpSessionId));
    yield put(setPaginaIdentidade("verificarCodigo"));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* verificarPodeUtilizarLoginSaga() {
  yield takeEvery("account/verificarPodeUtilizarLoginFetch", callVerificarPodeUtilizarLogin);
}

//configuração
function* callConfig(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(configuracaoEmpresa, action.payload);
    const response = data.data.data;
    document.documentElement.style.setProperty("--color-variable", response.corPrimaria);
    yield put(setConfig(response));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* configSaga() {
  yield takeEvery("login/configFetch", callConfig);
}
